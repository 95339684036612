<template>
  <div class="maintenance">
    <div class="maintenance-tittle">
      <div class="maintenance-tittle-text">{{$t('Maintenance')}}</div>
    </div>
    <div class="image">
      <img src="../assets/ai--robustness.svg">
    </div>
    <div class="maintenance-message">
      <div class="maintenance-message-text">{{$t('MaintenanceMessage')}}</div>
    </div>
  </div>
</template>

<script>


export default {
  name: "MaintenancePage",

}
</script>

<style scoped lang="scss">
.maintenance {
  height: 100px;
  margin: 10% 0;
  font-size: 24px;
  text-align: center;

  .image{
    margin: auto;
    height: 170px;
    width: 170px;
  }

  .maintenance-tittle{
    font-weight: bold;
    padding-bottom: 20px;
  }
}
</style>